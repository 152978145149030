// extracted by mini-css-extract-plugin
export var featureSelectorChangerOverwrite = "index-module--featureSelectorChangerOverwrite--qFyZm";
export var featureSelectorContainer = "index-module--featureSelectorContainer--LDUiS";
export var industriesWrapper = "index-module--industriesWrapper--FMxKr";
export var inputWrapperOverwrite = "index-module--inputWrapperOverwrite--Yi5kc";
export var joinInstitutionBodyClass = "index-module--joinInstitutionBodyClass--efpfN";
export var justTitleAndContentOverwrite = "index-module--justTitleAndContentOverwrite--UXh4y";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--c9aXy";
export var ourPartners = "index-module--ourPartners--QRDrD";
export var overrideFirstImage = "index-module--overrideFirstImage--St22v";
export var overrideImagesCard = "index-module--overrideImagesCard--3b8Wk";
export var overrideSecondImage = "index-module--overrideSecondImage--Do7cb";
export var pageWrapper = "index-module--pageWrapper--uCoJH";
export var paragraph = "index-module--paragraph--jD61G";
export var tabsGap = "index-module--tabsGap--7olYW";
export var testimonialOverwrite = "index-module--testimonialOverwrite--2e9FS";