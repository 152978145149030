import React, { ReactNode } from "react";
import * as styles from "./LearnMore.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { Accordion } from "@components/Accordion";

 const getBodyElement = (content: string | ReactNode) => {
	return (
		<TypographyV2
			variant="BODY_TEXT_EXTRA_LARGE"
			mobileVariant="BODY_TEXT_LARGE"
			weight="REGULAR"
			color="text-helper"
		>
			{content}
		</TypographyV2>
	);
};
export const SectionLearnMore = () => {
	
	const QnAs = [
		{
			title: "What is laboratory information management systems (LIMS)?",
			id: "1",
			defaultOpen: true,
			children: [
				`A laboratory information management system (LIMS) is a software solution designed to streamline laboratory management by improving laboratory information systems. The system keeps track of all data related to experiments test results, samples, instruments, and lab workflows. As well as standardizing information and procedures, a LIMS also provides control. This means that it’s easy for your team to collaborate remotely or in person.`,
				`Research laboratories accumulate vast amounts of data that require processing and analysis. Many labs handle thousands of samples each week. This makes it incredibly time-consuming to manually sort all the data and keep abreast of sample tracking. A LIMS solution is vital for any modern laboratory because it significantly improves several processes.`,
				`Scientists and lab technicians across many different disciplines use LIMS software. This solution is frequently found in pharmaceutical tracking, medical research, quality assurance, manufacturing, and environment monitoring labs. For example:`,
				<ul>
					<li>Clinical research and diagnosis</li>
					<li>Biobanks and biorepositories</li>
					<li>Agricultural testing labs</li>
					<li>Gas and oil testing labs</li>
				</ul>,
			],
		},
		{
			title: "Why use LIMS?",
			id: "2",
			children: [
				`In these times of big data, traditional methods of recording scientific information—such as record logs and spreadsheets—tend to be inadequate at keeping up with the demands of a modern laboratory. Recording, processing, and analyzing such large amounts of data manually is not only time-consuming but also leaves a lot of room for human error.`,
				<ul>
					<li>
						<b>Interfacing:</b> The integration of devices and
						software is key to optimizing your organization’s
						workflow. A LIMS permits faster and more efficient data
						management through automation.
					</li>
					<li>
						<b>Interoperability:</b> LIMS software interoperability
						helps your lab share information securely with
						third-party applications.
					</li>
					<li>
						<b>Reporting:</b> When it comes to reporting,
						visualization, and analytics are key factors. LIMS
						software can facilitate numerous reporting capabilities.
						This superior functionality allows you to convey
						information in various ways, as needed, to stakeholders,
						public health partners, and peer groups.
					</li>
					<li>
						<b>Economizing:</b> LIMS software reduces your
						laboratory overheads because it increases productivity
						(improving your economy of time), it automates all
						processes electronically (saving your system money by
						becoming paperless), it spots errors sooner and corrects
						them quicker, and finally it optimizes the monitoring of
						raw materials (enabling you to produce better quality
						results or products).
					</li>
					<li>
						<b>Standardizing:</b> A LIMS automation solution enables
						you to implement standard protocol, workflows, and
						throughputs. This ensures that all members of your team
						follow in-lab procedures unfailingly, which helps to
						maintain data integrity.
					</li>
					<li>
						<b>Accessioning:</b> A LIMS solution enables you to
						accession your samples as soon as they enter your lab,
						improving the efficiency of sample management and
						informatics.
					</li>
				</ul>,
			],
		},
		{
			title: "Benefits of LIMS",
			id: "3",
			children: [
				`LIMS can completely transform your laboratory into an innovative center of excellence. Here are some of the major benefits of adopting a LIMS solution:`,
				<ul>
					<li>
						<b>Efficiency:</b> Your team will begin to notice
						efficiency gains almost immediately with a LIMS because
						your lab protocols and information are centralized and
						allow easy access. Using a dashboard, where you can see
						real-time results, streamlines your workflow because
						each member of your team has your most frequently
						accessed tools at their fingertips whenever they need
						them.
					</li>
					<li>
						<b>Accuracy:</b> Errors can be virtually eliminated by
						automating tasks such as assignments, tracking progress,
						and validating data. LIMS integrates all applications so
						you can share and transfer data without running the risk
						of duplicating or omitting information, or missing
						entries.
					</li>
					<li>
						<b>Productivity:</b> Your lab can optimize productivity
						by sharing real-time data, providing clear timelines,
						and enhancing deliverables. Team members can cut down on
						overdue tasks and use automated tools to perform
						multiple calculations simultaneously.
					</li>
				</ul>,
			],
		},
	];
	
	return (
		<div className={styles.container}>
			<TypographyV2
				variant="HEADING_2"
				weight="MEDIUM"
				tabletVariant="HEADING_2"
				mobileVariant="HEADING_2"
				color="brand-500"
				className={styles.heading}
			>
				Learn more about LIMS
			</TypographyV2>

			{QnAs?.map((qna) => {
				return (
					<div key={qna.id}>
						<Accordion
							title={qna.title}
							defaultOpen={qna.defaultOpen}
						>
							{qna.children?.map((body_content, index) => {
								return (
									<div key={index}>
										{getBodyElement(body_content)}
									</div>
								);
							})}
						</Accordion>
					</div>
				);
			})}
		</div>
	);
};
